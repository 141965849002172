import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Meta, Title } from '@angular/platform-browser';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Breakpoints } from 'ln-templates-angular';

import * as translations from '../../shared/dictionary';
import { AuthService, ErrorService, AuthDataService } from '../../core/services';
import { IErrorData, IForgotPasswordData } from '../../core/models/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'wam-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  isXLargeScreen$: Observable<boolean>;
  username: string;
  inlineErrorDisplay: string;
  locale: string;
  aci: string;
  customerCareNumber: string;
  showCustomerCareNumber: boolean = false;
  hasPreviousPage: boolean = false;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected translations: any;

  constructor(
    private _observer: BreakpointObserver,
    private titleService: Title,
    private metaService: Meta,
    private authService: AuthService,
    private route: ActivatedRoute,
    private errorService: ErrorService,
    private location: Location, // Inject Location service
    private authDataService: AuthDataService,
    private router: Router) {
    this.translations = translations;
    this.setTitle();
    this.setMetaTag();
    this.setTitleBlock();
  }

  ngOnInit(): void {
    this.setCustomerCareNumber();
    this.isXLargeScreen$ = this._observer.observe(`(min-width: ${Breakpoints.XLARGE})`)
      .pipe(
        map((state: BreakpointState) => state.matches)
      );

      this.route.queryParams.subscribe((params) => {
        this.locale = params['locale'];
        this.aci = params['aci'];
      });
        // Check if there is a previous page in the navigation history
        this.hasPreviousPage = window.history.length > 1;
  }

  setTitle(): void {
    const productName = 'Lexis®';
    const pageTitleSuffix = 'Reset password | LexisNexis';
    const title = `${productName} - ${pageTitleSuffix}`;
    this.titleService.setTitle(title);
  }

  setMetaTag(): void {
    this.metaService.addTag(
      {
        name: 'description',
        content: this.translations.Login_Description_Meta_Tag,
      });
  }

  resetPasword() {
    this.inlineErrorDisplay = '';
    this.authService.resetPassword(this.username, this.locale, this.aci)
        .subscribe((response: IForgotPasswordData) => {
          this.authService.redirect(response.redirectUrl);
        }, (err: IErrorData) => {
          if (err && err.error && err.error.errorCode) {
            const code: string = err.error.errorCode;
            const errorCodes = ['472', '473', '474', '475', '476', '477', '478', '462'];
            if (code === '471') {
              this.inlineErrorDisplay = translations.Reset_Password_UserName_Required;
            } else if (code === '468') {
              this.showCustomerCareNumber = true;
              this.inlineErrorDisplay = translations.Error_OTP_Count_Account_Locked;
            } else if (errorCodes.includes(code)) {
              this.inlineErrorDisplay = translations.Reset_Password_InValid_UserName;
            }
          }
        });
  }

  setTitleBlock(): void {
    this.authDataService.setTitle(this.translations.Reset_Password_Header);
    this.authDataService.setDescription(this.translations.Reset_Password_Desc);
  }

  //back method to use the Location service's back method
  back(): void {
    if (this.hasPreviousPage) {
      this.location.back();
    }
  }

  private setCustomerCareNumber() {
    if (this.authService.clientData && this.authService.clientData.csPhoneNumber) {
      this.customerCareNumber = this.authService.clientData.csPhoneNumber;
    }
  }

  ngOnDestroy(): void {
    this.metaService.removeTag('name=description');
  }
}
