import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {BreakpointObserver, BreakpointState} from '@angular/cdk/layout';
import {Meta, Title} from '@angular/platform-browser';

import {Observable, throwError} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';

import {Breakpoints} from 'ln-templates-angular';

import * as translations from '../../shared/dictionary';
import {AppConfigService, AuthDataService, AuthService, ErrorService} from '../../core/services';
import { ActivatedRoute, Router } from "@angular/router";
import {
  AccessTokenInfo,
  IAccountActivationData,
  IErrorData,
  IHydraClient,
  IRedirectData,
  ISetPasswordData
} from "../../core/models/auth";
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import {AuthConfig, OAuthService, OAuthStorage} from "angular-oauth2-oidc";
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {CookieService} from "ngx-cookie-service";


export const authConfig: AuthConfig = {
  issuer: 'https://ddc4c-login.lexisnexis.com/',
  redirectUri: window.location.origin,
  clientId: 'auth-code-client3',
//  clientId: '7c4b160e-7bdc-472d-984a-14ac222dd4da',
  requireHttps: false,
  scope: 'openid offline',
  responseType: 'code',
  showDebugInformation: true
};

@Component({
  selector: 'wam-activate-account',
  templateUrl: './activate-account.component.html',
  styleUrls: ['./activate-account.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class ActivateAccountComponent implements OnInit, OnDestroy {
  isXLargeScreen$: Observable<boolean>;
  token: string;
  password: string;
  confirmpassword: string;

  inlineErrorDisplay: string;
  submitted = false;
  authForm: FormGroup = new FormGroup({});
  _patternErrorMap: { [key: string]: boolean };
  showPassword = false;
  srPasswordText = translations.LoginForm_PasswordHidden;
  passwordType = 'password';

  showConfirmPassword = false;
  srConfirmPasswordText = translations.LoginForm_PasswordHidden;
  confirmPasswordType = 'password';
  userName: string = '';
  showActivateAccountFormError : boolean = false;
  renderForm : boolean = false;
  activatedAccount = false;
  activateBtnValue = '';
  activatedBodyValue = '';
  submitBtnValue = '';
  redirectUrl = '';
  lastSigninDate = '';
  isResetPwMode: boolean = false;
  aci : any
  locale : any
  user : any

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected translations: any;
  private isGeneric: any;
  private localhostUrl: any;
  private challenge: string;
  private rememberLogIn: boolean;
  private headers: HttpHeaders;
  private loginId: string;
  lna2Cookie = '';
  private state: string;
  customerCareNumber: string;
  showCustomerCareNumber: boolean;

  constructor(
    private _observer: BreakpointObserver,
    private titleService: Title,
    private metaService: Meta,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private authDataService: AuthDataService,
    private errorService: ErrorService,
    private oauthService: OAuthService,
    private oauthStorage : OAuthStorage,
    private configService: AppConfigService,
    private http: HttpClient,
    private cookie: CookieService,
    private fb: FormBuilder) {

    this.translations = translations;
    this.setTitle();
    this.setMetaTag();
    this.setTitleBlock();
    this.createForm();
    this.oauthService.configure(authConfig);
    this.oauthService.loadDiscoveryDocument();
    //this.oauthService.tokenValidationHandler = new JwksValidationHandler();

  }

  ngOnInit(): void {
    this.isXLargeScreen$ = this._observer.observe(`(min-width: ${Breakpoints.XLARGE})`)
      .pipe(        map((state: BreakpointState) => state.matches)
      );

    this.route.queryParams.subscribe((params) => {
      this.token = params['token'];
      this.isGeneric = params['aci'];
      this.aci = params['aci'];
      this.locale = params['locale'];
    });
    this.getAccountActivationData();
    this.challenge = sessionStorage.getItem('challenge');

  }

  get c() {
    return this.authForm.controls;
  }

  createForm() {
    this.authForm = this.fb.group({
      password: [
        '',
        [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(50),
          this.cannotContainSpace,
          this.minPatternValidator(),
        ],
      ],
      confirmPassword: [
        '',
        [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(50),
        ],
      ],
    },
    {
      validators: this.matchValidator,
    });
  }

  setPassword(): void {
    this.inlineErrorDisplay = '';
    if (this.authForm.valid) {
      this.submitted = true;
      this.password = this.authForm.get('password').value;
      this.authService.setUsersPassword(this.token, this.authForm.get('password').value)
        .subscribe((response: ISetPasswordData) => {
          console.log("lna2cookie: " + response.lna2Cookie);
          console.log("RedirectUrl: " + response.redirectURL);
          this.lna2Cookie = response.lna2Cookie;
          this.redirectUrl = response.redirectURL;
          if((this.aci === 'tol'|| this.aci === 'toL') && this.locale === 'en-GB'){
            this.signInAfterActivation();
          }

          this.activateBtnValue = this.translations.Activate_Account_Button_Go_To_Product;
          this.activatedBodyValue = (this.isResetPwMode) ? this.translations.Activated_Account_Success_Set_Pw : this.translations.Activated_Account_Success;
          this.activatedAccount = true;
          this.renderForm = false;
          this.setTitleBlock();

          this.submitted = false;
          if(this.isGeneric === 'noAppContext'){
            this.signIn();
          }
        }, (err: IErrorData) => {
          console.log("errors");
          if (err && err.error && err.error.errorCode) {
            const code: string = err.error.errorCode;
            if (code === '451' || code === '452' || code === '453' || code === '454' || code === '455' || code === '456') {
              this.inlineErrorDisplay = translations.Password_requirements_not_met;
            } else if (code === '461') {
              this.inlineErrorDisplay = translations.Password_policy_violation;
            } else if (code === '458') {
              this.inlineErrorDisplay = translations.Password_set_link_expired;
            } else if (code === '457') {
              console.log("457");
              this.router.navigate(['error'], {queryParams: {code}});
            }
          }
          this.submitted = false;
        });
    } else {
      this.inlineErrorDisplay = translations.Password_empty;
    }
  }

  signIn(): void {
      this.submitted = true;
      this.authService.getAccessToken(this.loginId, this.password, this.token)
        .subscribe((response: AccessTokenInfo) => {
          this.oauthStorage.setItem('access_token', response.accessToken)
          this.oauthStorage.setItem('id_token', response.idToken)
          this.oauthService.setStorage(this.oauthStorage);
          this.cookie.set('lna2',this.lna2Cookie);
          this.router.navigate(['user/dashboard']);
          this.submitted = false;
        }, (error: IErrorData) => {
            this.router.navigate(['error'], { queryParams: { code: String(error.status) } });
          this.submitted = false;
        });
    }

  signInAfterActivation() : void{
    this.authService.login(this.loginId, this.password, this.challenge, false).subscribe(
            (response: IRedirectData) => {
              this.redirectUrl = response.redirectUrl;
            },
            (error: IErrorData) => {
              if (error.status === 403 && error.error.errorCode === '403.9') {
                this.errorService.redirectToAccessUrl(error);
              } else if (error.status === 409) {
                const data = JSON.parse(sessionStorage.getItem('clientData'));
                this.authService.redirect(data.landingPageURL);
              } else {
                this.setCustomerCareNumber();
                this.showCustomerCareNumber = this.errorService.isRequiresSupportMessage(error);
                this.errorService.getErrorMessage(error);
                this.errorService.inlineError.subscribe((msg) => {
                  this.inlineErrorDisplay = msg;
                });
              }
              this.submitted = false;
            }
          );
    }

    private setCustomerCareNumber() {
      if (this.authService.clientData && this.authService.clientData.csPhoneNumber) {
        this.customerCareNumber = this.authService.clientData.csPhoneNumber;
      }
    }


  getAccountActivationData(): void {
    this.authService.getAccountActivationData(this.token)
      .subscribe((response: IAccountActivationData) => {
        this.userName = response.firstName;
        this.lastSigninDate = response.lastSigninDate;
        this.isResetPwMode = (this.lastSigninDate) ? true : false;
        this.loginId = response.loginID;
        this.setActivatedContent();
        this.setTitleBlock();
        this.setTitle();
        this.showActivateAccountFormError = false;
        sessionStorage.setItem("userPermId", JSON.stringify(response.userPermId))
      }, (err: IErrorData) => {
        if (err && err.error && err.error.errorCode) {
          const code: string = err.error.errorCode;
          if (code === '458') {
            const message: string = err.error.errorMessage;
            this.handleExpiredLink(true, message);
          }
          if(code === '459.2' || code === '459.1'){
            this.activateBtnValue = this.translations.Activate_Account_Button_Go_To_Product;
            this.activatedAccount = true;
            this.renderForm = false;
            this.redirectUrl = err.error.redirectUrl;
            this.userName = err.error.firstName;
            this.lastSigninDate = err.error.lastSigninDate;

            this.setActivatedContent();
            this.setTitleBlock();
            this.setTitle();
          }
          if (code === '451' || code === '457') {
            // Route to error page in case of token issues
            this.router.navigate(['error'], {queryParams: {code}});
          }
        }
      },() => {
          this.renderForm = ! this.showActivateAccountFormError && ! this.activatedAccount;
      }
    );
  }

  setActivatedContent(): void {
    this.submitBtnValue = (this.isResetPwMode) ? this.translations.Activate_Account_Button_Set_Pw : this.translations.Activate_Account_Button;
    this.activatedBodyValue = (this.isResetPwMode) ? this.translations.Activated_Account_Success_Set_Pw : this.translations.Activated_Account_Success;
  }

  setTitle(): void {
    const tabActivity = (this.isResetPwMode) ? this.translations.Activate_Account_Tab_Set_Pw : this.translations.Activate_Account_Tab;
    const productName = 'Lexis®';
    const pageTitleSuffix = `${tabActivity} | LexisNexis`;
    const title = `${productName} - ${pageTitleSuffix}`;
    this.titleService.setTitle(title);
  }

  setMetaTag(): void {
    this.metaService.addTag(
      {
        name: 'description',
        content: this.translations.Login_Description_Meta_Tag,
      });
  }

  setTitleBlock(): void {
    const description = `${this.translations.Activate_Account_Desc} ${this.userName}`
    this.authDataService.setTitle((this.isResetPwMode) ? this.translations.Activate_Account_Heading_Set_Pw : this.translations.Activate_Account_Heading);
    this.authDataService.setDescription(description);
  }

  ngOnDestroy(): void {
    this.metaService.removeTag('name=description');
  }

  private minPatternValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }

      const digitRegExp = new RegExp('(?=.*[0-9])');
      const upperCaseRegExp = new RegExp('(?=.*[A-Z])');
      const lowerCaseRegExp = new RegExp('(?=.*[a-z])');
      const specialCharRegExp = new RegExp('^[a-zA-Z0-9!$%|#&\'*-?^_`{}~.@]+$');

      // These regexes are used to check patterns only when the value has characters outside the other patterns

      // Used to check special characters: includes characters outside alphanumeric
      const nonAlphaNumericRegExp = new RegExp('(?=.*[^a-zA-Z0-9])');

      // Used to check digits: includes characters outside special characters and alphabets
      const nonSpecialAndAlphaRegExp = new RegExp('(?=.*[^a-z-A-Z!$&\'*?^_`{}~.@])');

      // Used to check upper cases: includes characters outside special characters, numeric and lower case
      const nonSpecialLowerAlphaNumericRegExp = new RegExp('(?=.*[^a-z0-9!$&\'*-?^_`{}~.@])');

      // Used to check lower cases: includes characters outside special characters, numeric and upper case
      const nonSpecialUpperAlphaNumericRegExp = new RegExp('(?=.*[^0-9A-Z!$&\'*-?^_`{}~.@])');

      let validCount = 0;
      this._patternErrorMap = {
        nonAlphaNumeric: true,
        nonSpecialAndAlpha: true,
        nonSpecialLowerAlphaNumeric: true,
        nonSpecialUpperAlphaNumeric: true,
      };

      let hasInvalidPattern = false;

      if (nonSpecialAndAlphaRegExp.test(control.value)) {
        this._patternErrorMap.nonSpecialAndAlpha = false;

        // Check digit
        if (digitRegExp.test(control.value)) {
          validCount++;
        } else {
          this._patternErrorMap.requiresDigit = true;
          hasInvalidPattern = true;
        }
      }

      if (nonSpecialLowerAlphaNumericRegExp.test(control.value)) {
        this._patternErrorMap.nonSpecialLowerAlphaNumeric = false;

        // Check uppercase
        if (upperCaseRegExp.test(control.value)) {
          validCount++;
        } else {
          this._patternErrorMap.requiresUppercase = true;
          hasInvalidPattern = true;
        }
      }

      if (nonSpecialUpperAlphaNumericRegExp.test(control.value)) {
        this._patternErrorMap.nonSpecialUpperAlphaNumeric = false;

        // Check lowercase
        if (lowerCaseRegExp.test(control.value)) {
          validCount++;
        } else {
          this._patternErrorMap.requiresLowercase = true;
          hasInvalidPattern = true;
        }
      }

      if (nonAlphaNumericRegExp.test(control.value)) {
        this._patternErrorMap.nonAlphaNumeric = false;

        // Check special characters
        if (specialCharRegExp.test(control.value)) {
          validCount++;
        } else {
          this._patternErrorMap.requiresSpecialChars = true;
          hasInvalidPattern = true;
        }
      }

      return validCount > 2 && !hasInvalidPattern ? null : { pattern : validCount, hasInvalidPattern };
    };
  }

  private matchValidator(control: AbstractControl) {
    const password: string = control.get('password').value;
    const confirmPassword: string = control.get('confirmPassword').value;

    if (!confirmPassword || !confirmPassword.length) {
      return null;
    }

    if (confirmPassword.length < 8) {
      control.get('confirmPassword').setErrors({ minLength: true });
    } else {
      if (password !== confirmPassword) {
        control.get('confirmPassword').setErrors({ mismatch: true });
      } else {
        return null;
      }
    }
  }

  private cannotContainSpace(control: AbstractControl) {
    if ((control.value as string).indexOf(' ') >= 0) {
      return { cannotContainSpace: true };
    }
    return null;
  }

  togglePasswordMask(): void {
    this.showPassword = !this.showPassword;
    this.passwordType = this.showPassword ? 'text' : 'password';
    // text for screen reader only
    this.srPasswordText = this.showPassword ? translations.LoginForm_PasswordShown : translations.LoginForm_PasswordHidden;
  }

  toggleConfirmPasswordMask(): void {
    this.showConfirmPassword = !this.showConfirmPassword;
    this.confirmPasswordType = this.showConfirmPassword ? 'text' : 'password';
    // text for screen reader only
    this.srConfirmPasswordText = this.showConfirmPassword ? translations.LoginForm_PasswordShown : translations.LoginForm_PasswordHidden;
  }

  private handleExpiredLink(isExpired : boolean = true, errorMessage : string = '') : void {
    this.showActivateAccountFormError = isExpired;
    this.inlineErrorDisplay = translations.Password_set_link_expired;
    this.userName = errorMessage;
    this.setTitleBlock();
  }

  private redirectToProduct(){
    window.location.href = this.redirectUrl;
  }
}
